.alertContainer {
	gap: 7px;
	width: 100%;
	padding: 6px;
	display: flex;
	color: #000000;
	border-radius: 4px;
	background-color: #f8e9ed;
	justify-content: flex-start;
	font-family: "Poppins-Regular";
}

.alertContainer img {
	height: 18px;
	width: 18px;
}

.alertContainer span {
	font-size: 13px;
}
