.container {
    width: auto !important;
    margin: 25px 0px !important;
    padding: 0px !important;
}

.orange {
    color: orangered !important;
  } 

  .black {
    color: black !important;
  }
  
  .blue {
    color: blue !important;
  }
  .brGray {
    background-color: gray !important;
  }
  
  .drawerContainer {
    height: 50;
    overflow: 'auto',
  }
  
  .cancelIcon {
    height: 100%;
  }
  
  .pl10 {
    padding-left: 10px !important;
  }
  
  .primary {
    color: skyblue !important;
  }

  .padding {
    font: normal normal normal Poppins !important;
    padding-top: 20;
    padding-left: 20;
  }

  .table {
    min-width: 700;
    font: normal normal normal Poppins !important;
  }

  .poppins{
    font: normal normal normal Poppins !important;
  }