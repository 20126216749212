.sucessIcon {
	fill: #32aa4d !important;
	width: 4.5vw !important;
	max-width: 4.5vw !important;
	font-size: 5vw !important;
}

.iconContainer {
	display: flex;
	justify-content: center;
}

.modalTitle {
	font: normal normal 600 18px/24px Wavehaus-Regular !important;
	color: #3b3b3b;
	text-align: center;
	margin: 1em 0 0.5em 0 !important;
}

.modalMessage {
	font: normal normal normal 14px/21px Poppins-Regular !important;
	color: black;
	text-align: center;
	margin: 0.5em 1.5em 1em 1.5em !important;
}

.modalActions {
	display: flex;
	justify-content: center;
	font-size: 2px;
}

.button {
	margin-left: auto !important;
	margin-right: auto !important;
	min-width: 120px !important;
	min-height: 38px !important;
	background-color: #32aa4d;
	font: normal normal white 8px Poppins-Regular !important;
	color: white;
	font-size: 8px;
}
.button:hover {
	background-color: #32aa4d;
	font: normal normal white 8px Poppins-Regular !important;
	color: white;
	font-size: 8px;
}
