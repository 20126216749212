.circular {
    align-items: center;
}

.message {
    align-items: center;
}

.MuiPaperRoot {
    align-content: center;
    opacity: 1;
}

.errorIcon {
    color: #D93025;
    width: 63px !important;
    height: 63px !important;
}

.logout {
    text-align: center;
    font-size: 18px;
    font-weight: 550;
    letter-spacing: 0px;
    color: #3b3b3b;
    opacity: 1;
    margin: 0;
    padding-top: 25px;
}

.dialog {
    background: #FFFFFF;
    border-radius: 0;
    opacity: 1;
    width: 357px;
    height: auto;
}

.logoutBtn {
    width: 125px;
    height: 42px;
    border: 1px solid #CED4DA;
    background-color: #32AA4D !important;
    border-radius: 4px;
    opacity: 1;
    font-weight: 500;
    font-size: 15px;
}

.logoutIcon > g > :global(path.a){
    fill: blue;
    align-content: center;
}

.logoutIcon {
    height: 47px;
    width: 47px;
    display: inline-block;
    align-content: center;
}

.btnLabel {
    font-size: 11px;
    color: #FFFFFF;
}

.p_message {
    margin: 0;
    color: black;
    letter-spacing: 0px;
    opacity: 1;
}
