.cardContainer {
	width: 100%;
	min-height: 115px;
	display: flex;
	color: #ffffff;
	margin-top: 10px;
	border-radius: 4px;
	flex-direction: column;
	padding: 6px 12px 8px 12px;
	font-family: "Poppins-Regular";
	justify-content: space-between;
}

.balanceContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.balanceContainer span {
	font-size: 14px;
	font-weight: bold;
	font-family: "Poppins-Regular";
}

.balanceContainer h1 {
	margin: 0;
	font-size: 22px;
	font-family: "Poppins-Medium";
}

.icon {
	width: 48px;
	height: 48px;
}

.body {
	gap: 12px;
	display: flex;
	margin-top: 13px;
	margin-bottom: 32.5px;
	justify-content: flex-start;
}

.footer {
	display: flex;
	flex-direction: column;
}

.footer * {
	font-size: 12px;
}

.footer div:first-child {
	margin-bottom: 4px;
}

.column {
	display: flex;
	justify-content: space-between;
}

.column > div {
	width: 50%;
}

.green {
	background-color: #13ad72;
}

.red {
	background-color: #bc224c;
}

.gray {
	background-color: #aeb2b7;
}
