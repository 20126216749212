@import "../../../global.css";

.header {
	text-align: left;
	padding-bottom: 5px !important;
	padding-top: 15px;
	padding-left: 15px;
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
	border-bottom: 1px solid #a8abad;
	display: flex;
	align-items: center;
}

.title {
	flex: 1;
}

.close {
	flex: 1;
	color: #8b9095;
	cursor: pointer;
}

.message {
	font: normal normal normal 14px Poppins-Regular !important;
	padding-top: 30px !important;
	padding-left: 15px;
	padding-right: 15px;
}

.reasonText {
	font: normal normal normal 14px Poppins-Regular !important;
	padding-left: 15px;
	padding-top: 23px;
}

.dialogPaper {
	width: 600px;
	height: fit-content;
	padding: 0;
	margin: 0;
}

.reasonFooter {
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex;
	justify-content: right;
	border-top: 1px solid gray;
}

.maxMessage {
	text-align: left;
	font-size: 12px !important;
	font-family: Poppins-Light !important;
	font-style: italic;
	letter-spacing: 0px;
	color: #8b9095;
	opacity: 1;
	margin: 5px 2px 15px 0px !important;
	padding-left: 15px;
}

.errorMessage {
	text-align: left;
	font-size: 12px !important;
	font-family: Poppins-Light !important;
	letter-spacing: 0px;
	color: #ff0000;
	opacity: 1;
	margin: 5px 2px 15px 0px !important;
	padding-left: 15px;
}

button.enable,
button.enable:disabled {
	width: 125px;
	height: 42px;
	color: #ffffff;
	background: #6dd262;
	border: 1px solid #6dd262;
	border-radius: 4px;
	opacity: 1;
}

button.enable:hover {
	background: #138906;
	border: 1px solid #139c04;
}

button.enable:hover,
button.enable:active,
button.enable:focus {
	background: #139c04;
	border: 1px solid #139c04;
}

button.disabled {
	border: 1px solid #ced4da;
	border-radius: 4px;
	opacity: 1;
	width: 125px;
	height: 42px;
}
