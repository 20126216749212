@import "../../global.css";

.divContainer {
	height: 80vh;
	min-height: 100%;
	max-width: 100%;
}

.root {
	min-height: 100vh;
	margin: auto;
	padding-left: 30px;
	padding-right: 30px;
	text-align: center;
}

.img {
	display: block;
	max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 15px !important;
	margin-top: 15px !important;
}

.title {
	margin: 0;
	font: normal normal bold 22px/26px Wavehaus-Bold !important;
	letter-spacing: 0.78px;
	line-height: 2;
	color: #f26122;
}

.subtitle {
	margin: 0;
	font: normal normal 600 16px/21px Wavehaus-SemiBold !important;
	letter-spacing: 0.49px;
	color: #3b3b3b;
}

.contentText {
	margin: 0;
	font: normal normal 16px/21px Wavehaus-Regular !important;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.cardContainer {
	padding: 30px;
	max-width: 550;
	width: 550;
}

.textLeft {
	text-align: left;
}

.textCenter {
	text-align: center;
}

.mail {
	color: #0076BF;
}
