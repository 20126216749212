@import "../../global.css";

.img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 20% !important;
	margin-bottom: 20px !important;
}

.imgSuccess {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 20% !important;
	margin-bottom: 25px !important;
	margin-top: 40px !important;
}

.header{
	text-align: center;
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
}

.headerSuccess{
	text-align: center;
	font: normal normal normal 18px/21px Wavehaus-SemiBold !important;
	margin: 0px 20px 40px 20px !important;
}

.body{
	text-align: left;
	font: normal normal normal 13px/21px Poppins-Regular !important;
	margin: 25px !important;
}

.button{
	margin-left: auto !important;
	margin-right: auto !important;
	min-width: 125px !important;
	min-height: 42px !important;
	font: normal normal normal 12px/18px Poppins-Regular !important;
	background-color: #32AA4D  !important;
	color: white  !important;
	cursor: pointer;
}

.button-disable{
	margin-left: auto !important;
	margin-right: auto !important;
	min-width: 125px !important;
	min-height: 42px !important;
	font: normal normal normal 12px/18px Poppins-Regular !important;
	background-color: #32aa4eaf  !important;
	color: white  !important;
	cursor: pointer;
}

.dialogPaper{
	width: 350px;
}