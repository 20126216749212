@import "../../global.css";

.billerHeader {
	background-color: var(--lightGray);
	padding: 5px 10px 5px 16px;
	vertical-align: top;
	margin-bottom: 3px;
	cursor: pointer;
}

.headerLabel {
	vertical-align: super;
	text-align: left;
	font: normal normal normal 12px/18px Poppins-Regular;
	letter-spacing: 0.33px;
	color: var(--black);
}

.billerImage {
	height: 26px;
	width: 22px;
	object-fit: contain;
	vertical-align: sub;
	margin-right: 10px;
}

.transactionForm {
	margin-top: 10px;
}

.textInput {
	width: 100%;
	font: normal normal normal 10px/16px Poppins-Regular;
}

.removeButton {
	text-align: left;
	font: normal normal 600 10px/16px Poppins-Regular;
	letter-spacing: 0.28px;
	color: var(--black);
}

.addTransactionButton {
	font: normal normal 600 12px/18px Poppins-Regular;
	color: var(--headerBlue);
}

.addTransactionIcon {
	color: var(--headerBlue);
	height: 15px;
	width: 15px;
}

.addTransactionDisabled {
	filter: grayscale(0.9) !important;
	cursor: not-allowed !important;
}

.addTransactionContainer {
	padding: 15px 0px;
}

.modePaymentHeader {
	font: normal normal 600 12px/14px Poppins-Regular;
	letter-spacing: 0px;
	color: var(--black);
}

.paymentKeyHeader {
	font: normal normal normal 9px/14px Poppins-Regular;
	letter-spacing: 0px;
	color: var(--black);
}

.paymentFormGroup {
	padding-top: 10px;
	padding-bottom: 10px;
}

.customerDetailsFormGroup {
	padding-top: 20px;
	padding-bottom: 20px;
	@media (min-width:1025px) {
		flex-wrap: nowrap !important;
	}
}

.txnHeader {
	height: 20px;
	font: 15px Poppins-Regular;
	font-weight: 600;
	text-align: left;
	color: #36393d;
	opacity: 1;
	margin-top: -5px;

}

.dialogContent {
	width: 392px;
	font: normal normal normal 14px/21px Poppins-Regular;
}

.expandIcon {
	position: relative;
	float: right;
}
