.loadingBarBg {
	background: black;
	opacity: 0.5;
	z-index: 2;
	position: absolute;
	width: 100%;
	height: 100%;
    justify-content: center;
    display: flex;
    flex-flow: column;
}