.text {
    letter-spacing: 0;
    color: #FFFFFF;
    text-align: left;
}

.textLarge {
    font: normal normal normal 10px/12px Poppins-Regular !important;
}

.textSmall {
    font: normal normal normal 8px/12px Poppins-Regular !important;
}