button.enable {
	width: 125px;
	height: 42px;
	color: #ffffff;
	background: #6dd262;
	border: 1px solid #32aa4d;
	border-radius: 4px;
	opacity: 1;
}

button.enable:hover {
	background: #138906;
	border: 1px solid #139c04;
}

button.enable:hover,
button.enable:active,
button.enable:focus {
	background: #139c04;
	border: 1px solid #139c04;
}

button.disabled {
	border: 1px solid #ced4da;
	border-radius: 4px;
	opacity: 1;
	width: 125px;
	height: 42px;
}

.container {
	border-top: "1px solid #8b9095";
}

.input {
	width: 92%;
	height: 159px;
	text-align: start !important;
	background: #ffffff 0% 0% no-repeat padding-box;
	border: 1px solid #dadce0;
	border-radius: 4px;
	opacity: 1;
	margin: 18px 18px 0px 18px;
	font: normal normal normal 12px/18px;
	letter-spacing: 0px;
	color: #8b9095;
}

.max_message {
	text-align: left;
	font-size: 12px !important;
	font-family: Poppins-Light !important;
	font-style: italic;
	letter-spacing: 0px;
	color: #8b9095;
	opacity: 1;
	margin: 0px 2px 5px 5px !important;
}

.all_pending_status_message {
	text-align: left;
	font-size: 15px !important;
	font-weight: bolder !important;
	font-family: Poppins-Light !important;
	letter-spacing: 0px;
	opacity: 1;
	margin: 0px 2px 5px 5px !important;
}

.pending_word {
	color: #f26122;
}

.unable_to_print {
	text-align: left;
	font-size: 21px !important;
	letter-spacing: 0px;
	color: #3b3b3b;
	font-weight: bold;
	opacity: 1;
	flex-grow: 1;
	font-family: Poppins-Light !important;
}

.checkbox {
	border: 1px;
	color: #b6b8bd !important;
	opacity: 1;
	padding: 0 !important;
}

#textfieldForm {
	padding-bottom: 0 !important;
}
