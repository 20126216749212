.container {
  display: flex;
  width: 100%;
  gap: 12px;
  border-bottom: 2px #0176C0 solid;
  margin-bottom: 36px;
}

.container .tab {
  color: #000000;
  padding: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-size: 14px;
  background-color: #E9F5FD;
  user-select: none;
  transition: all 300ms ease-in-out;
}

.container .tab:hover {
  cursor: pointer;
}

.container .tab.active {
  color: #ffffff;
  background-color: #0176C0;
}