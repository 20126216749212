.fullpageMain {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background-color: black;
	opacity: 0.7;
	z-index: 9000;
}

.fullpageBody {
	left: 50%;
	top: 50%;
	position: fixed;
	overflow-y: hidden;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.fullpageMessage {
	font: normal normal normal 12px Poppins-Regular !important;
	margin-bottom: 1vh;
	color: white;
	text-align: center;
	line-height: normal !important;
	white-space: pre-line;
}
.fullpageTimer {
	font: normal normal normal 12px/37px Poppins-Regular;
	color: white;
	margin-bottom: 0;
	padding-bottom: 0;
}
