@import "../../global.css";

.dialogPaper {
	padding: 12px 0px !important;
}

.title {
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
}

.body {
	color: black !important;
	font: normal normal normal 13px/20px Poppins-Regular !important;
}

.buttonCloseIcon{
	position: absolute !important;
    right: 1px;
    top: 1px;
}

.buttonClose {
	padding: 10px 35px !important;
	background-color: white !important;
	color: black !important;
}

.buttonContinue {
	padding: 10px 35px !important;
	background-color: #32AA4D !important;
	color: white !important;
	border: 1px solid #32AA4D !important;
}

