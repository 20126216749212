.countHeader {
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
}

.dialogPaper {
	width: 500px;
    height: auto !important;
}

.countBody {
	font: normal normal normal 14px/21px Poppins-Regular !important;
	margin-left: 25px !important;
	margin-right: 25px !important;
	margin-bottom: 25px !important;
	margin-top: 25px !important;
}

.buttonLogout {
	background-color: #32aa4d !important;
	color: white !important;
	min-height: 45px !important;
	margin-bottom: -10px !important;
}

.buttonLogin {
	min-height: 45px !important;
	margin-bottom: -10px !important;
	margin-left: -450px !important;
}

.closeButton {
	width: 30px !important;
	height: 10px !important;
	margin-top: 15px !important;
	margin-left: 270px !important;
}
