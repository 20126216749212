.statusSuccess {
	color: #28a745;
}
.statusFailed {
	color: #d93025;
}
.statusProcessing {
	color: #f26122;
}
.statusReprocessing {
	color: #cccf19;
}
.modal {
	font-family: Poppins-Medium !important;
}
.modalLabel {
	font-family: Poppins-Medium !important;
}
.modalValue {
	font-family: Poppins-Regular !important;
}

.modalDialogTitle {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: baseline;
	margin-top: 5px;
}

.voidReference {
	font-weight: 600;
	color: #0076c0;
	cursor: pointer;
}

.disabled {
	opacity: 0.4;
}
