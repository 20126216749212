.title {
    font: normal normal 600 14px/16px Wavehaus-SemiBold;
    color: #3B3B3B;
}

.filterContainer {
    margin: 0px !important;
    border: 2px solid #F26122;
    border-radius: 10px !important;
}

.filterLabel {
    font: normal normal 12px/18px Poppins-Medium !important;
    color: #4B5051 !important;
}

.button {
    height: 38px !important;
}

.previewButton {
    composes: button;
    border: 1px solid #0076C0 !important;
    color: #0076C0 !important;
    
}

.printButton {
    composes: button;
    background: #0076C0 0% 0% no-repeat padding-box !important;
    border: 1px solid #0076C0 !important;
    color: #FFFFFF !important;
}
.previewButton:hover {
    background-color: rgba(0, 118, 192, 0.04) !important;
}

.label {
    font: normal normal 12px/18px Poppins-Regular !important;
}

.buttonDisabled {
    background: #F6A07B 0% 0% no-repeat padding-box !important;
    color: #FFFFFF !important;
}

.printCheckContainer {
    border: 1px solid #DADFE2;
    margin: 0px !important;
    padding: 0px 16px !important;
}

.printCheckTitle {
    font: normal normal 12px/18px Poppins-Medium;
    color: #4B5051;
    margin-bottom: 0px;
}

.menuItemList:hover {
    background: #F3FAFF 0% 0% no-repeat padding-box !important;
    color: #F26122 !important;
}

.menuItemList {
    font: normal normal 12px/30px Poppins-Regular !important;
    letter-spacing: 0.11px;
}

.unableToPrintItem {
    composes: menuItemList;
    color: #D93025 !important;
}

.menuItemFocusVisible {
    background: #F3FAFF 0% 0% no-repeat padding-box !important;
}

.menuListSelected {
    background: #F3FAFF 0% 0% no-repeat padding-box !important;
    color: #F26122 !important;
}

.confirmModalButton {
    border: none;
    margin: 5px;
    min-width: 80px;
    min-height: 30px;
    padding: 5px;
    border: 1px solid #808080;
    border-radius: 4px;
    cursor: pointer;
}

.confirmModalButton:hover {
    border: 1px solid #F26122;
}

.confirmModalBody {
    padding: 10px;
    font-size: 15px;
}

.disabled {
    background-color: #72B3DB !important;
    border: 1px solid #72B3DB !important;
}

.printButtonRoot > span {
    font: normal normal 12px/18px Poppins-Regular !important;
}

.bodyPrintWindow {
  border: 1px solid transparent;
}


