.container {
	margin: auto;
	width: 450px;
	min-width: 450px; 
	padding: 0 1.5vw;
}

.errorIcon{
	fill: #D93025 !important;
	width: 5vw!important;
	max-width: 5vw !important;
	font-size: 5vw!important;
}

.iconContainer {
	display: flex;
	justify-content: center;
}

.modalTitle {
	font: normal normal bold 1vw Poppins-Regular !important;
	color: black;
	text-align: center;
	margin: 1em 0 1.5em 0!important;;
}

.modalMessage {
	font: normal normal lighter 0.8vw Poppins-Regular !important;
	color: black;
	text-align: center;
	margin: .5em 1.5em 1em 1.5em !important;
}

.modalActions {
	display: flex;
	justify-content: center;
	font-size: 2px;
}

.button {
	margin-left: auto !important;
	margin-right: auto !important;
	min-width: 120px !important;
	min-height: 38px !important;
	font: normal normal normal 8px Poppins-Regular !important;
}