.header {
	font-family: var(--filter-font-medium);
	font-weight: 500;
	font-size: 1.5rem;
	margin-bottom: 1.5em;
}

.container {
	padding: 0 1em;
}

.stat {
	font-family: var(--filter-font-medium);
}

.statLabel {
	font-size: 1rem;
}

.statValue {
	color: #0076c0;
	font-size: 1.8rem;
}

.statBorder {
	border-right: 1px solid #cac4c4;
}
