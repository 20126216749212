.appBar {
	background: #0076bf 0 0 no-repeat padding-box !important;
	opacity: 1;
	margin: 0 !important;
	padding: 5px !important;
}

.toolBar {
	/* padding-right: 10px;
	padding-left: 10px; */
}

.appBarButton {
	background: #0076bf 0 0 no-repeat padding-box;
	border-radius: 20px !important;
	opacity: 1;
	color: #ffffff !important;
	width: auto;
	height: 36px;
}

.appbarBtnDisabled > :global(span.MuiButton-label) {
	color: #a6cfe9 !important;
}

.appBarButton:disabled:active {
	background-color: #a6cfe9 !important;
}

.appBarButton:disabled:not(active) {
	color: #a6cfe9;
}

.appBarButton:active,
.appBarButton:focus,
.appBarButton:hover {
	background-color: #f3f6f9 !important;
	color: #0076bf !important;
}

.btnActive {
	background-color: #f3f6f9 !important;
	color: #0076bf !important;
}

.textTitle {
	font: normal normal bold 13px/15px Poppins-Regular !important;
	letter-spacing: 0;
	color: "fff" !important;
	padding: 8px;
}

.textSmall {
	font: normal normal normal 10px/15px Poppins-Regular !important;
	letter-spacing: 0;
}

.textNormal {
	font: normal normal normal 13px/15px Poppins-Regular !important;
	letter-spacing: 0;
}

.dialogContent {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 24px;
}

.logoutImg {
	height: 20%;
	width: 20%;
	display: flex;
	align-items: center;
	padding: 10px;
	margin: 14px;
}

.logOutButton {
	color: white;
	font: normal normal bold 13px/15px Poppins-Regular !important;
}
