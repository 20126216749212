.filterContainer {
	border: 2px solid #f26122;
	border-radius: 10px;
	padding: 1em;
}

.clearBtnLbl {
	color: #0076c0;
}

.seeMoreLabel {
	color: #0176c0;
	position: absolute;
	bottom: 0;
	padding: 1em;
	/* background-color: white; */
	/* height: ''; */
}
