.backdrop {
    z-index: 1 !important;
    color: #fff;
}

.note {
    color: white !important;
    font: normal normal lighter 12px/16px Poppins-Regular !important;
}

.progress {
    color: white !important;
    font: normal normal lighter 12px/18px Poppins-Regular !important;
}

.icon {
    color: #FB7823 !important;
}