.scheduleDateContainer {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 20px;
	margin-top: 30px;
	padding-bottom: 18px;
}

.scheduleDatePicker {
	display: flex;
	flex-direction: row;
	flex-grow: 0.8;
	gap: 5px;
}

.scheduleTimePicker {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 3.5px;
}

.scheduleTimePicker > div {
	display: flex;
	flex-direction: row;
	gap: 12px;
}

.saveButton {
	background: none;
	border: none;
	cursor: pointer;
}

.approveIconDisable path {
	fill: #808080;
}
