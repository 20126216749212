.textStyles6001012WavehausSemi {
  margin: 0;
  font: normal normal 600 10px/12px Wavehaus-SemiBold;
  letter-spacing: 0.49px;
}

.textStyles6001214WavehausSemi {
  margin: 0;
  font: normal normal 600 12px/14px Wavehaus-Regular;
  letter-spacing: 0px;
}

.textStylesNormal1315Poppins {
  margin: 0;
  font: normal normal normal 13px/15px Poppins-Regular;
  letter-spacing: 0px;
}

.textStylesNormal1016Poppins {
  margin: 0;
  font: normal normal normal 10px/16px Poppins-Regular;
  letter-spacing: 0px;
}


.textStylesNormal1218Poppins {
  margin: 0;
  font: normal normal normal 12px/18px Poppins-Regular;
  letter-spacing: 0.59px;
}

.textStylesNormal1418Poppins {
  margin: 0;
  font: normal normal normal 14px/18px Poppins-Regular;
  letter-spacing: 0.59px;
}


.textStylesNormal1418PoppinsLight {
  margin: 0;
  font: normal normal normal 14px/18px Poppins-Light;
  letter-spacing: 0.59px;
}

.textStylesNormal1218PoppinsLight {
  margin: 0;
  font: normal normal normal 12px/18px Poppins-Light;
  letter-spacing: 0.59px;
}

.textStylesNormal1117Poppins {
  margin: 0;
  font: normal normal normal 11px/17px Poppins-Regular;
  letter-spacing: 0.31px;
}

.textStylesMedium1113Poppins {
  margin: 0;
  font: normal normal normal 11px/13px Poppins-Medium;
  letter-spacing: 0px;
}

.textStylesMedium1014Poppins {
  margin: 0;
  font: normal normal normal 10px/14px Poppins-Regular;
  letter-spacing: -0.15px;
}

.textStylesNormal812Poppins {
  margin: 0;
  font: normal normal normal 8px/12px Poppins-Regular;
  letter-spacing: 0.39px;
}

.textStylesBold1619WavehausBold {
  margin: 0;
  font: normal normal bold 16px/19px Wavehaus-Bold;
  letter-spacing: 0.78px;
}


.textStyle1619WavehausSemi {
  text-align: left;
  font: normal normal 600 16px/19px Wavehaus-SemiBold;
  letter-spacing: 0.44px;
}



.textStylesNormal1421Poppins {
  margin: 0;
  font: normal normal normal 14px/21px Poppins-Regular;
  letter-spacing: 0.59px;
}