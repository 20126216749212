body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

body,
html,
div#root {
	height: 100%;
}

@font-face {
	font-family: Wavehaus-Bold;
	src: url(assets/fonts/Wavehaus/Wavehaus-128Bold.otf);
}

@font-face {
	font-family: Wavehaus-SemiBold;
	src: url(assets/fonts/Wavehaus/Wavehaus-95SemiBold.otf);
}

@font-face {
	font-family: Wavehaus-Regular;
	src: url(assets/fonts/Wavehaus/Wavehaus-66Book.otf);
}

@font-face {
	font-family: Wavehaus-Light;
	src: url(assets/fonts/Wavehaus/Wavehaus-42Light.otf);
}

@font-face {
	font-family: Poppins-Light;
	src: url(assets/fonts/Poppins/Poppins-Light.ttf);
}

@font-face {
	font-family: Poppins-Regular;
	src: url(assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
	font-family: Poppins-Medium;
	src: url(assets/fonts/Poppins/Poppins-Medium.ttf);
}

@font-face {
	font-family: SaxMono;
	src: url(assets/fonts/SaxMono/saxmono.ttf);
}

@font-face {
	font-family: MerchantCopy;
	src: url(assets/fonts/MerchantCopy/MerchantCopy.ttf);
}

@font-face {
	font-family: Monaco;
	src: url(assets/fonts/Monaco/Monaco.ttf);
}

#notification-popover .MuiPaper-root.MuiPopover-paper {
	padding: 0;
}

/* Temporary fix for invisible iframe that can't click components */
iframe[style*="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
	display: none;
}
