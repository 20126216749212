.gridContainer {
    background: #FDD45A 0 0 no-repeat padding-box;
    opacity: 1;
    height: 100%;
}

.changeFundCard {
    height: inherit;
}

.changeFundLogo {
    width: 58px;
    height: 58px;
}

.amountField {
    width: inherit;
}

.changeFundTitle {
    text-align: center;
    font: normal normal normal 16px/25px Poppins-Regular;
    letter-spacing: 0;
    color: #495057;
    opacity: 1;
    padding-bottom: 1em;
}

.buttonGridItem {
    width: 100%;
    text-align: center;
}

.sessionButton {
    height: 42px;
    width: inherit;
    border-radius: 4px;
    opacity: 1;
    font: normal normal normal 14px/21px Poppins-Regular;
    letter-spacing: 0;
}

.withSession {
    background: var(--orange) 0 0 no-repeat padding-box !important;
    border: 1px solid var(--orange);
    color: var(--white) !important;
}

.withSession:hover,
.withSession:active {
    background: var(--darkOrange) 0 0 no-repeat padding-box;
    border: 1px solid var(--darkOrange);
}

.withSession:disabled {
    background: #F6A07B  0 0 no-repeat padding-box !important;
    border: 1px solid #F6A07B;
}

.withoutSession {
    border: 1px solid var(--orange) !important;
    color: var(--orange) !important;
}
.withoutSession:hover,
.withoutSession:active{
    background: var(--orange) 0 0 no-repeat padding-box !important;
    border: 1px solid var(--orange) !important;
    color: var(--white) !important;
}