.filterContainer {
	border: 2px solid #f26122;
	border-radius: 10px;
	padding: 1em;
}

.filterLabel {
	font-size: 14px;
	font-family: Poppins-Medium;
}

.clearBtnLbl {
	color: #0076c0;
}

.seeMoreLabel {
	color: #0176c0;
	position: absolute;
	bottom: 0;
	padding: 1em;
}

.note {
	color: #0076bf;
	font-size: 14px;
	font-family: Poppins-Regular;
	padding-top: 1em;
	padding-right: 1.8em;
}
