.sortButton{
    borderRadius: "8px";
    padding: "5px";
    background: "#F3F3F3";
}

.label {
    font: normal normal normal 14px/14px Poppins-Regular !important;
}

.logo {
    object-fit:cover;
    width: 40px;
    margin: auto;
}

.logo-wrapper{
    text-align: center;
    /* border: solid gray 1px; */
    border-radius: 9px;
    width: 45px;
    height: 45px;
    overflow: hidden; 
    display: flex;
    align-items: center;
    background-color: white;
    justify-content: center;
}

.heart {
    cursor: pointer;
}

.search {
    font: normal normal normal 14px/16px Poppins-Regular !important;
}
.favorite-note{
    /* font: italic normal 30px/30px Poppins-Regular; */
    font: normal normal normal 15px/17px Poppins-Regular !important;
}

.tab{
    font: normal normal normal 14px/16px Poppins-Medium !important;
    color: #3b3b3b;
}

.category_items{
    font: normal normal normal 15px/16px Poppins-Regular !important;
}

.category_label{
    font: normal normal normal 15px/20px Poppins-Regular !important;
}

.category_list{
    width: 155px;
}

.alert_info{
    font: normal normal normal 14px/18px Poppins-Regular !important;
}

.textfield_wrapper fieldset {
    border-radius: 10px !important;
    border-color: #e0dede5e;
    width: 100%;
}

.svg_icons {
    transform: scale(1.7);
  }

.svg_small{
    transform: scale(1.3);
}