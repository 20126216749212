:root {
  /* colors */
  --headerBlue: #0076C0;
  --black: #3b3b3b;
  --lightGray: #F6F5F7;
  --white: #ffffff;
  --gray: #626262;
  --orange: #F26122;
  --darkOrange: #E1571C;
  /* --darkerBlack: #000000DE; */
  --darkerGray: #3E3F42;
  --dirtyWhite: #F1F4F8;
  --lightOrange: #FB7823;
  --grayWhite: #8B9095;
  --lightBlack: #36393D;
  --ligtherGray: #666D75;

  /* fonts */
  --common-font: Wavehaus;

  background-color: var(--lightGray);
  font: var(--common-font);

}

