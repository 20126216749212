.img {
	background-color: white;
	height: 28px;
	width: 28px;
	padding: 4px 3px 4px 4px;
	border-radius: 100px;
	cursor: pointer;
}

.badge {
	margin-right: 16px;
}

.header {
	font-size: 14px;
	font-weight: bold;
	color: var(--orange);
	display: inline-block;
	margin: 10px 12px 4px 12px;
	font-family: Poppins-Regular;
}

.listBody {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	align-items: flex-start;
	max-width: 300px;
}

.content,
.datetime {
	max-width: 250px;
	min-width: 146px;
	font-family: Poppins-Regular;
}

.datetime {
	font-size: 11px;
}

.content {
	/* margin-top: 10px !important; */
	color: #8b9095;
	font-size: 13px;
}

.datetime {
	display: block;
	margin-top: 4px;
}

.actionsContainer {
	margin-left: auto;
}

.actionsBody {
	gap: 12px;
	display: flex !important;
	margin-left: 25px;
}

.statusDetails {
	display: flex !important;
	margin-left: 100px;
}

.statusDetailsReprint {
	display: flex !important;
	margin-left: 125px;
}

.action {
	cursor: pointer;
}

.dialogDeclined {
	width: 330px !important;
}
.headerError {
	font: normal bold normal 19px Poppins-Regular !important;
	font-weight: bold;
	margin-top: 1.5vh !important;
	margin-bottom: 1.5vh !important;
}
.contentError {
	margin: 2px !important;
	text-align: center !important;
}
.contentText {
	font: normal normal normal 15px Poppins-Regular !important;
	margin-bottom: 2vh !important;
}
.buttonOkay {
	/* background-color: #32aa4d !important; */
	/* color: white !important; */
	min-height: 45px !important;
	width: 140px !important;
	margin-top: 1vw !important;
	margin-bottom: -5px !important;
}

.pill {
	align-items: center;
	text-align: center;
	font-weight: bold;
	letter-spacing: 0.7px;
	font: normal normal 600 10px/12px Poppins-Regular !important;
	align-content: center;
	display: inline-block;
	height: auto;
	white-space: nowrap;
	width: 74px;
	padding-top: 4px;
	padding-bottom: 4px;

	position: relative;
	border-radius: 100px;
	line-height: 1;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.5vw;
	color: #effaee;

	word-break: break-word;
}
.pill_approved {
	background: #6cd164;
}
.pill_declined {
	background: #eb9691;
}
.pill_invalid {
	background: #72b3db;
}
.pill_cancelled {
	background: #b3b7bc;
}

.bodyText {
	font: normal normal normal 12px/13px Poppins-Regular;
	font-weight: bold;
}

.errorNotif {
	opacity: 0.9 !important;
	background-color: #dd443a !important;
	color: white !important;
}

.successNotif {
	opacity: 0.9 !important;
	background-color: #309365 !important;
	color: white !important;
	z-index: 8000 !important;
}

.errorNotif svg,
.successNotif svg {
	color: white !important;
	z-index: 8000 !important;
}

#root .MuiSnackbar-anchorOriginBottomRight:nth-child(2) {
	bottom: 92px !important;
}
