.reminderWrapper {
	background: #e5e78b 0% 0% no-repeat padding-box;
	border: 1px solid #cccf19;
	border-radius: 5px;
	min-height: 134px;
}

.textContainer {
	font: normal normal normal 10px/16px Poppins-Regular !important;
	margin: 15px 46px;
}

.icon {
	position: absolute;
	margin: 15px 14px;
}
