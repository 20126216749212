button.enable {
    width: 164px;
    height: 42px;
    color: #FFFFFF;
    background: #32AA4D;
    border: 1px solid #32AA4D;
    border-radius: 4px;
    opacity: 1;
    font: normal normal normal 12px/18px Poppins-Regular !important;
}

button.enable:hover {
    background: #138906;
    border: 1px solid #139C04;
}

button.enable:hover,
button.enable:active,
button.enable:focus {
    background: #139C04;
    border: 1px solid #139C04;
}

button.enable:disabled {
    background: #6DD262;
    color: #FFFFFF;
    border: none;
}


.modalTitle {
    font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
    color: #3B3B3B;
}

.modalBody {
    font: normal normal normal 14px/21px Poppins-Regular !important;
}

.modalDialog {
    padding: 0 !important;
}

