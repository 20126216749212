.summaryHeader{
    font: normal normal normal 12px/18px Poppins-Regular;
    font-weight: 600;
    letter-spacing: 0;
    text-align: left;
    color: #212529;
    opacity: 1;
    text-transform: uppercase;
    margin-top: 25px;
}

.dialogContent {
    width: 392px;
    font: normal normal normal 14px/21px Poppins-Regular;
}
