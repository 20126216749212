:root {
	/*
	****
	* PRINTER DEFAULT MARGIN SETTINGS
	****
	* Need to subtract to get exact position, using calc()
	****
	*/
	--marginMinimum: 0.3048cm;
	--marginDefault: 2.54cm;
}

.modalBody
	> :global(div.MuiDialog-container)
	> :global(div.MuiPaper-root)
	> :global(div.modal-body) {
	background-repeat: no-repeat !important;
	background-size: contain !important;
	height: 1000px;
}
.modalBody > :global(div.MuiBackdrop-root) {
	background-color: #ffffff !important;
}

.positionBayadFormTop {
	margin-top: 17.3rem;
	margin-left: calc(0.9cm - var(--marginMinimum));
}

.positionBayadFormBottom {
	top: 15.3cm;
	position: absolute;
	margin-left: calc(0.9cm - var(--marginMinimum));
}

.positionSOATop {
	position: absolute;
	top: calc(0.94cm - var(--marginMinimum));
	left: calc(4cm - var(--marginMinimum));
}

.positionSOABottom {
	position: absolute;
	top: calc(23.5cm - var(--marginMinimum));
	left: calc(11.8cm - var(--marginMinimum));
}
