.select > label {
    font: normal normal 12px/18px Poppins-Regular !important;
    letter-spacing: 0px !important;
}

.select > :global(label.MuiInputLabel-outlined) {
    transform: translate(14px, 13px) scale(1) !important;
}

.select > :global(label.MuiInputLabel-shrink) {
    transform: translate(14px, -6px) scale(0.75) !important;
   
}

.select > :global(label.MuiInputLabel-shrink.Mui-focused){
    color: #0076C0 !important;
}

.select > :global(div.MuiInputBase-root) > :global(div.MuiSelect-select:focus) {
    background-color: #fff !important;
}

.select > :global(div.MuiInputBase-root) {
    height: 42px;
}

.select > :global(div.MuiOutlinedInput-root.Mui-focused) > :global(.MuiOutlinedInput-notchedOutline) {
    border-color: #0076C0 !important;
}

.select > :global(div.MuiOutlinedInput-root.Mui-focused) > :global(.MuiOutlinedInput-input) {
    color: #0076C0 !important;
}

.select > :global(div.MuiOutlinedInput-root.Mui-focused) {
    color: #0076C0 !important;
}

.select > :global(label.MuiFormLabel-filled) {
    color: #3B3B3B !important;
}

.select > :global(label.MuiFormLabel-filled) + :global(div.MuiOutlinedInput-root)  > :global(.MuiOutlinedInput-notchedOutline) {
    border: 1px solid #3B3B3B;
}

.select > :global(div.MuiInputBase-root) > :global(div.MuiSelect-root.MuiSelect-select) {
    font: normal normal 12px/18px Poppins-Regular;
    letter-spacing: 0px;
    color: #3B3B3B;
}

.menuItemList:hover {
    background: #F3FAFF 0% 0% no-repeat padding-box !important;
    color: #F26122 !important;
}

.menuItemList {
    font: normal normal 12px/30px Poppins-Regular !important;
    letter-spacing: 0.11px;
}

.menuItemFocusVisible {
    background: #F3FAFF 0% 0% no-repeat padding-box !important;
}

.menuListSelected {
    background: #F3FAFF 0% 0% no-repeat padding-box !important;
    color: #F26122 !important;
}