.labelBorder{
    border-bottom: 0.5px solid rgba(139, 144, 149, .3);
}

.accordionSummary{
    max-height: 13px !important;
    min-height: 8px !important;
    padding-top: 10px !important;
}

.logoutButtons:disabled{
    background-color: #F6A07B !important;
    color: var(--white) !important;
    font: normal normal normal 12px/18px Poppins !important;
}

.remarks{
    margin-top: 50px !important;
}

.failedImage{
    margin-left: 40%;
}