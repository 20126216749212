.backBtn {
    font: normal normal normal 14px/21px Poppins-Regular;
    color: #3E3F42;
}

.contactText {
    font: normal normal normal 14px/21px Poppins-Regular;
    color: #3E3F42;
}

.contactTitle {
    font: normal normal 600 18px/21px Wavehaus-SemiBold;
    color: #0076C0;
}