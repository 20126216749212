.close {
	color: #8b9095;
	cursor: pointer;
    margin: 0;
}

.exitConfirmationClose {
	color: #8b9095;
	cursor: pointer;
    margin: 0;
	flex: 1;
}

.exitConfirmationHeader {
	text-align: left;
	padding-bottom: 5px !important;
	padding-top: 15px;
	padding-left: 15px;
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
	border-bottom: 1px solid #a8abad;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.exitConfirmationMessage {
	font: normal normal normal 14px Poppins-Regular !important;
	text-align: left;
	padding-top: 30px !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
	padding-bottom: 30px !important;
	border-bottom: 1px solid #a8abad;
}

.exitConfirmationButtons {
	padding : 10px 10px 10px 0 !important;
}


/* 
Void Confirm Modal Style
*/
.voidConfirmModalTitle {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-left: 5px;
}

.voidConfirmModalTitleText {
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
}

.voidConfirmModalContent {
	padding: 25px 0px 33px 6px;
}

.voidConfirmModalContentText {
	font: normal normal normal 14px/21px Poppins-Regular !important;
}