button.enable,
button.request {
	width: 100%;
	height: 42px;
	color: #ffffff;
	background: #0076c0;
	border-radius: 4px;
	opacity: 1;
	font: normal normal normal 12px/18px Poppins-Regular !important;
}

button.enableMargin {
	width: 125px;
	margin-left: 34px;
}

button.enable:hover,
button.request:hover {
	background: #05598d;
}

.divider {
	border-top: 1px solid #dadce0;
}

.topDivider {
	border-top: 1px solid #aaaaaa;
}

.or {
	font: normal normal normal 12px/18px Poppins-Regular !important;
	color: #8b9095;
}

button.enable:disabled {
	background: #72b3db;
	color: #ffffff;
	border: none;
}

.modalTitle {
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
	color: #3b3b3b;
}

.modalBody {
	font: normal normal normal 12px/19px Poppins-Regular !important;
	border-color: red 2px solid;
}

.notice {
	font: normal normal normal 12px/19px Poppins-Regular !important;
	color: #8b9095;
	font-style: italic !important;
}

.textInputFocus {
	border: 1px solid black;
}

.errorTextInput {
	border-color: red !important;
}

input[type="password"].passcode::-ms-reveal,
input[type="password"].passcode::-ms-clear {
	display: none;
}

.successRequestDialog {
	width: 357px !important;
	min-height: 270px !important;
	text-align: center;
}

.successRequestText {
	margin-bottom: 26px !important;
	font: normal normal 600 17px/20px Poppins-Regular !important;
	font-weight: bold !important;
}

.successRequestBodyText {
	font: normal normal 14px/17px Poppins-Regular !important;
	margin-bottom: 70px !important;
	margin-left: 19px !important;
	margin-right: 19px !important;
}
