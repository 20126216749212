.tabContainer {
	display: flex;
	border-bottom: 2px solid #0179BD;
}

.cTab {
	width: max-content;
	min-width: 100px;
	height: 40px;
	display: flex;
	padding: 12px 8px 2px 8px;
	margin-right: 12px;
	justify-content: end;
	flex-direction: column;
	background-color: #E8F6FC;
	border-radius: 10px 10px 0 0;

	cursor: pointer;
}

.cTab span {
	font-size: 14px;
	text-align: center;
	margin-bottom: 4px;
	font-family: Wavehaus-SemiBold;
}

.active {
	color: #FFFFFF;
	background-color: #0179BD;
}

.tabPanel {
	padding-top: 12px;
}