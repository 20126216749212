:root {
	--filter-font: Poppins-Regular;
	--filter-font-medium: Poppins-Medium;
}

.header {
	font-family: var(--filter-font-medium);
	font-size: 1.2rem;
}

.version {
	font-family: var(--filter-font);
	font-size: 0.8rem;
}

.confirmModalButton {
    border: none;
    margin: 5px;
    min-width: 80px;
    min-height: 30px;
    padding: 5px;
    border: 1px solid #808080;
    border-radius: 4px;
    cursor: pointer;
}

.confirmModalButton:hover {
    border: 1px solid #F26122;
}

.confirmModalBody {
    padding: 10px;
    font-size: 15px;
}

.refreshButton {
	background-color: #0076BF !important;
	color: white !important;
	border: 1px solid #0076BF !important;
    width: 100%;
    padding: 15px !important;
}

.refreshIcon {
    transform: rotate(180deg) !important;
    font-size: 25px !important;
}
