.modalContent {
	width: 407px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.reminderImg {
	margin-bottom: 27px;
}

.title {
	margin-bottom: 16px;
	font-size: 18px;
	font-family: Wavehaus-SemiBold;
}

.body {
	font-size: 14px;
	text-align: center;
	font-family: Poppins-Regular;
}

.actionContainer {
	margin-top: 26px;
}

.actionContainer button {
	width: 145px;
	height: 42px;
}
