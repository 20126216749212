:root {
	--filter-font: Poppins-Regular;
	--filter-font-medium: Poppins-Medium;
}

.header {
	font-family: var(--filter-font-medium);
	font-size: 1.2rem;
}

.version {
	font-family: var(--filter-font);
	font-size: 0.8rem;
}

.confirmModalButton {
	border: none;
	margin: 5px;
	min-width: 80px;
	min-height: 30px;
	padding: 5px;
	border: 1px solid #808080;
	border-radius: 4px;
	cursor: pointer;
}

.confirmModalButton:hover {
	border: 1px solid #f26122;
}

.confirmModalBody {
	padding: 10px;
	font-size: 15px;
}

.seeMoreLabel {
	color: #0176c0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 8px;
}

.seeMoreLabel:hover {
	cursor: pointer;
}

.modalContainer {
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.billerModalContainer {
	border-radius: 10px;
	border: 1px #0076c0 solid;
	overflow: hidden;
	margin: 1em;
}

.billerModalTitle {
	color: #ffffff;
	background-color: #0076c0;
	padding: 1em 1.5em;
	font-family: Poppins-Medium;
}

.billerModalOptions {
	max-height: 45vh;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 16px;
}

.billerModalOptions > div {
	font-family: Poppins-Regular;
	min-width: 400px;
	padding: 1em 1.5em;
}

.billerModalOptions > div.padding {
	height: 48px;
}

.billerModalOptions > div:not(.padding):hover,
.billerModalOptions > div:nth-child(even):not(.padding):hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.04);
}

.billerModalOptions > div.selected {
	color: #f26122;
}

.tooltipHeader {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
}

.retryButton {
	background-color: #f26122 !important;
	color: #ffffff !important;
	border: 1px solid #f26122 !important;
	width: 40%;
	margin-top: 10px;
	align-self: center;
	padding: 8px 0;
}

.retryButton:hover {
	background-color: #f8540d !important;
}

.retryDialog {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.retryTitle {
	font: normal normal 600 21px Wavehaus-SemiBold, Poppins-Regular !important;
	text-align: center !important;
	margin-top: 15px !important;
}

.retryText {
	font: normal normal 300 14px Poppins-Regular !important;
	text-align: center !important;
	margin-top: 15px !important;
	color: #3b3b3b !important;
}
