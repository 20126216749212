@import "../../global.css";

.img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 20% !important;
	margin-bottom: 15px !important;
}

.header{
	text-align: center;
	margin-bottom: 9px !important;
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
}

.body{
	text-align: center;
	font: normal normal normal 14px Poppins-Regular !important;
	margin-bottom: 25px !important;
}

.button{
	margin-left: auto !important;
	margin-right: auto !important;
	min-width: 94px !important;
	min-height: 36px !important;
	font: normal normal normal 12px Poppins-Regular !important;
}

.dialogPaper{
	width: 350px;
}