.container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.container img {
	width: 170px;
	height: 170px;
}

.container h4 {
	margin: 24px 0 0 0;
	font-weight: bold;
	color: var(--orange);
	font: var(--common-font);
	font-size: 18px !important;
}

.subText {
	color: #8b9095;
	margin-top: 14px;
	text-align: center;
	font-style: italic;
	font-family: "Poppins-Regular";
}

.blue {
	color: #0076c0;
}
