@import "../../global.css";

.policyBackground {
    background-color: var(--dirtyWhite);
}

.policyContentContainer {
    margin-top: 60px;
    margin-left: 50px;
    max-height: 700px;
    overflow: auto;
}

.policyContentContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}
.policyContentContainer::-webkit-scrollbar {
    width: 6px;
}
.policyContentContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #989898;
}

.bar {
    height: 60px;
    background-color: #0076BF;
}

.box {
    position: relative;
    display: inline-block;
}
.bfaLogo {
    margin-top: 15px;
    margin-left: 22px;
}

.backBtn {
    font: normal normal normal 14px/21px Poppins-Regular;
    color: var(--darkerGray);
}

.privacyTitle {
    margin-left: 35%;
    text-align: center;
    color: var(--orange);
    font: normal normal 600 32px/56px Wavehaus-SemiBold;
}

.contents {
    font: normal normal normal 12px/20px Poppins-Regular;
    color: var(--black);
}

.contentHeader {
    font: normal normal normal 18px/20px Poppins-Medium;
    color: var(--headerBlue);
    margin-top: 35px;
}