.wrapper {
	padding: 21px;
}

.detailsValue {
	text-align: right;
	font: normal normal bold 16px/37px Poppins-Regular !important;
	letter-spacing: 0.17px;
	color: #3b3b3b;
}

.title {
	font: 14px/21px Poppins-Regular;
	padding-left: 4px;
	font-weight: bold;
}

.detailsRow {
	border-bottom: 1px #8b909565 solid;
	padding: 10px 4px;
	font: 12px/37px Poppins-Regular;
	font-weight: bold;
}

.detailsRow:last-child {
	border-width: 1px 0 0 0;
}

.generateButton {
	padding: 12.5px 10px !important;
}

.failedImage {
	margin-left: 40%;
}
