.leftLogin {
	height: 578px;
	width: 391px;
	background-color: white;
	border-radius: 4px 0px 0px 4px;
}

.message {
	text-align: center;
	color: #0076c0;
	font: normal normal 600 22px/26px Wavehaus-SemiBold;
	margin-top: 94px;
}

.tpaSelectMessage {
	composes: message;
	margin-top: 16px;
}

.tpaSucessLoginMessage {
	composes: message;
	font-size: 16px;
	line-height: 26px;
	color: #495057;
	margin-top: 46px;
}

.loginBtn {
	width: 75%;
}

.captcha {
	font: normal normal normal 9px/13px Poppins-Regular;
	color: #00000099;
	text-align: left;
	padding: 0px 48px;
}

.anchor {
	text-decoration: none;
	color: #0077c0;
}

.rights {
	width: 391px;
	font: normal normal normal 10px/16px Poppins-Regular;
}

.links {
	width: 529px;
	text-align: right;
	padding-right: 120px;
	font: normal normal normal 12px/18px Poppins-Regular;
}

.links a {
	cursor: pointer;
}

.box {
	position: relative;
	display: inline-block;
}

.box .text1 {
	position: absolute;
	z-index: 999;
	margin-left: 14%;
	left: 0;
	right: 0;
	top: 68%;
	text-align: center;
	width: 60%;
	font: normal normal normal 18px/27px Poppins-Regular;
	color: white;
}

.box .text2 {
	position: absolute;
	z-index: 999;
	left: 0;
	right: 0;
	top: 74%;
	text-align: center;
	width: auto;
	padding-right: 22%;
	padding-left: 6%;
	font: normal normal normal 12px/18px Poppins-Regular;
	color: white;
}

.modalTitle {
	font: normal normal 18px/21px Wavehaus-SemiBold !important;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.modalRoot {
	border-bottom: 0.5px solid #8b9095;
}

.cardRoot {
	padding: 0px !important;
}

.closeButtonLabel {
	font: normal normal 12px/18px Poppins-Regular !important;
}

.formLabel {
	font: normal normal 12px/18px Poppins-Regular;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.select > label {
	font: normal normal 12px/18px Poppins-Regular !important;
	letter-spacing: 0px !important;
}

.select > :global(label.MuiInputLabel-outlined) {
	transform: translate(14px, 13px) scale(1) !important;
}

.select > :global(label.MuiInputLabel-shrink) {
	transform: translate(14px, -6px) scale(0.75) !important;
}

.select > :global(label.MuiInputLabel-shrink.Mui-focused) {
	color: #0076c0 !important;
}

.select > :global(div.MuiInputBase-root) > :global(div.MuiSelect-select:focus) {
	background-color: #fff !important;
}

.select > :global(div.MuiInputBase-root) {
	height: 42px;
}

.select
	> :global(div.MuiOutlinedInput-root.Mui-focused)
	> :global(.MuiOutlinedInput-notchedOutline) {
	border-color: #0076c0 !important;
}

.select
	> :global(div.MuiOutlinedInput-root.Mui-focused)
	> :global(.MuiOutlinedInput-input) {
	color: #0076c0 !important;
}

.select > :global(div.MuiOutlinedInput-root.Mui-focused) {
	color: #0076c0 !important;
}

.select > :global(label.MuiFormLabel-filled) {
	color: #3b3b3b !important;
}

.select
	> :global(label.MuiFormLabel-filled)
	+ :global(div.MuiOutlinedInput-root)
	> :global(.MuiOutlinedInput-notchedOutline) {
	border: 1px solid #3b3b3b;
}

.select
	> :global(div.MuiInputBase-root)
	> :global(div.MuiSelect-root.MuiSelect-select) {
	font: normal normal 12px/18px Poppins-Regular;
	letter-spacing: 0px;
	color: #3b3b3b;
}

.menuItemList:hover {
	background: #f3faff 0% 0% no-repeat padding-box !important;
	color: #f26122 !important;
}

.menuItemList {
	font: normal normal 12px/30px Poppins-Regular !important;
	letter-spacing: 0.11px;
}

.menuItemFocusVisible {
	background: #f3faff 0% 0% no-repeat padding-box !important;
}

.menuListSelected {
	background: #f3faff 0% 0% no-repeat padding-box !important;
	color: #f26122 !important;
}

.inSessionSpan {
	font: italic normal 10px/30px Poppins-Regular;
	letter-spacing: 0.09px;
	color: #28a745;
}

.disabled {
	font: italic normal 10px/30px Poppins-Regular;
	letter-spacing: 0.09px;
	color: red;
	display: inline-block;
	margin-left: 2px;
}

.timerText {
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
	font-size: 2.1vmax;
	margin-top: 20px !important;
	color: #0076c0;
}

.waitingText {
	font: normal 14px/21px Poppins-Regular !important;
	margin-top: 10px !important;
	margin-bottom: 20px !important;
	color: #3b3b3b;
}

.timerLogo {
	-webkit-animation: spin 3.5s linear infinite;
	-moz-animation: spin 3.5s linear infinite;
	animation: spin 3.5s linear infinite;
}

@-moz-keyframes spin {
	0% {
		transform: rotate(360deg);
	}
	50% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
@-webkit-keyframes spin {
	0% {
		transform: rotate(360deg);
	}
	50% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(360deg);
	}
	50% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(0deg);
	}
}

.dialogCancel {
	width: 27.5vmax;
}

.dialogInfo {
	max-width: 360px;
}

.dialogDeclined {
	width: 360px;
}

.dialogApprove {
	width: 278px;
	height: 200px;
	padding: 20px !important;
}

.header {
	font: normal normal 600 20px/22px Wavehaus-SemiBold !important;
	margin-top: 1vh;
	margin-bottom: 1.5vh;
}

.headerDecline {
	font: normal normal 600 20px/22px Wavehaus-SemiBold !important;
	margin-top: 12px !important;
}

.bodyDeclined {
	margin-top: 14px;
	margin-bottom: 14px;
}

.content {
	margin-top: 2px;
	margin-bottom: 2px;
	margin-left: 0.5px;
	margin-right: 0.5px;
	text-align: center;
	font-family: var(--common-font);
}

.contentCancel {
	margin: 20px;
}

.contentText {
	font: normal normal normal 15px Poppins-Regular !important;
}

.contentApprove {
	margin: 25px !important;
	text-align: center;
	font-family: var(--common-font);
}

.headerApprove {
	font: normal normal 600 20px/23px Wavehaus-SemiBold !important;
	margin-top: 22px !important;
}

.headerCancel {
	font: normal normal 600 20px/22px Wavehaus-SemiBold !important;
}

.buttonCancel {
	min-height: 37px !important;
	margin-bottom: -10px !important;
}

.buttonConfirm {
	min-height: 37px !important;
	margin-bottom: -10px !important;
	color: #ffffff !important;
	background-color: #138906 !important;
}

.buttonOkay {
	min-height: 45px !important;
	width: 140px;
	margin-top: 1vw;
	margin-bottom: -5px !important;
}

.closeButton {
	width: 30px !important;
	height: 10px !important;
	margin-top: 10px !important;
	margin-left: auto !important;
}
