.container {
	margin: auto;
	width: 450px;
	min-width: 450px; 
	padding: 0 2vw;
}

.sucessIcon{
	fill: #32AA4D !important;
	width: 4.5vw!important;
	max-width: 4.5vw !important;
	font-size: 5vw!important;
}

.iconContainer {
	display: flex;
	justify-content: center;
}

.modalTitle {
	font: normal normal normal 1vw Poppins-Regular !important;
	color: black;
	text-align: center;
	margin: 1em 0 .5em 0!important;;
}

.modalMessage {
	font: normal normal lighter 0.9vw Poppins-Regular !important;
	color: black;
	text-align: center;
	margin: .5em 1.5em 1em 1.5em !important;
}

.modalActions {
	display: flex;
	justify-content: center;
	font-size: 2px;
}

.button {
	margin-left: auto !important;
	margin-right: auto !important;
	min-width: 120px !important;
	min-height: 38px !important;
	background-color: #32AA4D;
	font: normal normal white 8px Poppins-Regular !important;
	color: white;
	font-size: 8px;
}
.button:hover {
	background-color: #32AA4D;
	font: normal normal white 8px Poppins-Regular !important;
	color: white;
	font-size: 8px;
}