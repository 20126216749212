@import "../../global.css";

.img {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 20% !important;
	margin-bottom: 15px !important;
}

.header{
	margin-bottom: 9px !important;
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
}

.body{
	font: normal normal normal 14px Poppins-Regular !important;
	margin-bottom: 25px !important;
}
.buttonWhite{
	width: 126px;
	height: 42px;
	color: #4B5051 !important;
	background-color: #FFFFFF !important;
	border: 1px solid #CED4DA !important;
	&:hover {
		background-color: #edf0f3 !important;
	};
}

.buttonGreen{
	width: 126px;
	height: 42px;
	color: #FFFFFF !important;
	background-color: #32AA4D !important;
	&:hover {
		background-color: #138906 !important;
	};
}

.dialogContent {
	width: 392px;
	font: normal normal normal 16px/21px Poppins-Regular !important;
}

.dialogPaper{
	width: 350px;
}