.downloadImage{
    margin-left: 45%;
  
}

.failedImage{
    margin-left: 40%;
}

.doneButton:disabled{
    background-color: #5ac774 !important;
    color: var(--white) !important;
    font: normal normal normal 12px/18px Poppins !important;
}