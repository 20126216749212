
.textTitle {
    font: normal normal bold 13px/15px Poppins-Regular !important;
    letter-spacing: 0;
    color: var(--white);
}

.modalContainer {
    background: #FDD45A 0 0 no-repeat padding-box;
    opacity: 1;
    height: 100%;
}


.question{
    color: #0076c0;
    font: normal normal bold 17px Wavehaus-SemiBold !important;
}

.reminder{
    font: normal normal normal 12px/18px Poppins-Regular !important;
    margin-top: 10px !important;
    font-size: 14px !important;
}

.logoutButtons{
    background-color: var(--orange) !important;
    color: var(--white) !important;
    margin-top: 30px !important;
    font: normal normal normal 12px/18px Poppins !important;
    width: 100%;
    height: 47px;
}

.logoutButtons:disabled{
    background-color: #F6A07B !important;
    color: var(--white) !important;
    margin-top: 30px !important;
    font: normal normal normal 12px/18px Poppins !important;
    width: 100%;
    height: 47px; 
}

.inputBox{
    margin-top: 5px !important;
    margin-left: 10px !important;
}


.backButton{
    background: #AFAFAF 0% 0% no-repeat padding-box !important;
    width: 100%;
    height: 47px;
}

.totalAmount{
    margin-bottom: -10px !important;
    font: normal normal bold 14px Poppins-Regular !important;
    color: #3B3B3B !important;
}

.numberFormat{
    font: normal normal normal 15px Poppins-Regular !important;
    color: #3B3B3B !important;
    opacity: 1;
    margin-top: 13px !important;
    margin-bottom: 22px !important;
}

.headerText{
    letter-spacing: var(--unnamed-character-spacing-0);
    color: var(--unnamed-color-3b3b3b);
    text-align: left;
    font:  normal normal normal 12px Poppins-Regular !important;
    color: #3B3B3B;
    opacity: 1;
}

.headerTextQuantity{
    text-align: left;
    font: normal normal normal 12px Poppins-Regular !important;
    color: #3B3B3B;
    opacity: 1;
    margin-left: 10px !important;
}

.tempContainer{
    background:var(--white) 0 0 no-repeat padding-box;
    height: 100%;
}

.tempKeep{
    background-color: var(--white) !important;
    height: 42px;
    width: inherit;
    border-radius: 4px;
    opacity: 1;
    letter-spacing: 0;
}

.tempLogout{
    background-color: #32AA4D !important;
    color: var(--white) !important;
    height: 42px;
    width: 1000px !important;
    border-radius: 4px;
    opacity: 1;
    letter-spacing: 0;
}

.tempHeader{
    font: normal normal normal 21px Wavehaus-SemiBold !important;
    color: #3B3B3B;
}

.tempBody{
    font: normal normal normal 17px Poppins-Regular !important;
    color: #3B3B3B;
}

.cashButtons{
    padding-right: 18px !important;
}