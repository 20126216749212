:root {
	--filter-font: Poppins-Regular;
	--filter-font-medium: Wavehaus-SemiBold;
}

.hover {
	cursor: pointer;
}

.header {
	font-family: var(--filter-font-medium);
	font-size: 14px;
}

.version {
	font-family: var(--filter-font);
	font-size: 0.8rem;
}

.confirmModalButton {
	border: none;
	margin: 5px;
	min-width: 80px;
	min-height: 30px;
	padding: 5px;
	border: 1px solid #808080;
	border-radius: 4px;
	cursor: pointer;
}

.confirmModalButton:hover {
	border: 1px solid #f26122;
}

.confirmModalBody {
	padding: 10px;
	font-size: 15px;
}

.searchbar {
	width: 216px;
	height: 42px;
	float: right;
}

.branchAction {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.freeColumn {
	width: 88px;
	min-width: 88px;
}

.branchAccessControlTable {
	max-height: 340px;
}

.tpaidText {
	color: #b2b8bc;
	font-weight: 600;
	font-size: 12px;
	margin-left: 12px;
}

.searchbar svg {
	color: #757575;
}

.statusInvalid {
	color: #b3b7bb;
}
.statusCancelled {
	color: #b3b7bb;
}
.statusApproved {
	color: #11bb03;
}
.statusDeclined {
	color: #d92f25;
}
.statusActive {
	color: #28a745;
	white-space: nowrap;
}
.statusActiveNoSched {
	color: #28a745;
	white-space: nowrap;
}
.statusInactive {
	color: #8b9095;
}
.statusBlocked {
	color: #d92f25;
}
.statusTemporaryBlocked {
	color: #0176c0;
}

input[type="password"].passcode::-ms-reveal,
input[type="password"].passcode::-ms-clear {
	display: none;
}

button.generate {
	width: 138px;
	margin-left: 15px;
	height: 42px;
	color: #ffffff;
	background: #0076c0;
	border-radius: 4px;
	opacity: 1;
	font: normal normal normal 7px/13px Poppins-Regular !important;
}

button.generate:hover {
	background: #05598d;
}

button.generate:disabled {
	background: #72b3db;
	color: #ffffff;
	border: none;
}

.headerCode {
	font: bold 14px/21px Poppins-Regular !important;
	margin-top: 18px !important;
}

.modalBody {
	height: 100px !important;
	width: 100px !important;
}

.errorNotif {
	opacity: 0.9 !important;
	background-color: #dd443a !important;
	color: white !important;
	z-index: 8000 !important;
}

.successNotif {
	opacity: 0.9 !important;
	background-color: #309365 !important;
	color: white !important;
	z-index: 8000 !important;
}

.errorNotif svg,
.successNotif svg {
	color: white !important;
}

#root .MuiSnackbar-anchorOriginBottomRight:nth-child(2) {
	bottom: 92px !important;
}
