@import "../../global.css";

.termsBackground {
    background-color: var(--dirtyWhite);
}

.bfaLogo {
    margin-top: 15px;
    margin-left: 22px;
}

.backBtn {
    font: normal normal normal 14px/21px Poppins-Regular;
    color: var(--darkerGray);
}

.termsTitle {
    padding-bottom: 30px;
    margin-left: 33%;
    text-align: center;
    color: var(--orange);
    font: normal normal 600 32px/56px Wavehaus-SemiBold;
}

.bar {
    height: 60px;
    background-color: #0076BF;
}

.termsContentContainer {
    padding: 20px 50px 10px 50px;
}

.termsContentContainer::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}
.termsContentContainer::-webkit-scrollbar {
    width: 6px;
}
.termsContentContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #989898;
}

.termsContent {
    text-align: left;
    font: normal normal normal 12px/16px Poppins-Regular;
    color: var(--black);
}

.termsContentHeader {
    text-align: left;
    font: normal normal 600 18px/16px Poppins-Regular;
    color: var(--headerBlue);
    letter-spacing: 0.5px;
}

.indentedText {
    text-indent: 10px;
}

.helpText {
    font-size: 12px;
    letter-spacing: 0px;
}

.header1 {
    font-size: 18px;
    color: #f26122;
    margin-bottom: '40px';
}

.header2 {
    font-size: 18px;
    color: #0076c0;
}

.header3 {
    color: #0076c0;
    font-size: 18px;
}

.regularText {
    font-family: 'Poppins-Regular'
}

.tabHeader {
    font: 14px
}

.mediumText {
    font-family: 'Poppins-Medium'
}

.indentedText10 {
    padding-left: 10px;
}

.indentedText20 {
    padding-left: 20px;
}

.indentedText30 {
    padding-left: 30px;
}
    
.indentedText40 {
    padding-left: 40px;
}

.indentedText80 {
    padding-left: 80px;
}

.indentedText120 {
    padding-left: 120px;
}

.indentedText10, .indentedText20, .indentedText30, .indentedText40, .indentedText80, .indentedText120 {
    display: inline-block;
}
