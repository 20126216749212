.dialog {
	overflow-x: hidden;
}

.dialogTitle {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-left: 5px;
}

.dialogTitleText {
	font: normal normal 600 18px/21px Wavehaus-SemiBold !important;
}

.dialogContent {
	padding: 25px 0px 60px 6px;
}

.dialogContentHint {
	color: #8b9095;
	margin-top: 4px;
	font-size: 10px;
	font-style: italic;
	font-family: Poppins-Light;
}

.dialogContentText {
	font: normal normal normal 14px/21px Poppins-Regular !important;
}

.dialogCancelButton {
	width: 125px;
	height: 42px;
	border: 1px solid #ced4da !important;
	border-radius: 4px !important;
	opacity: 1;
}

.dialogConfirmButton {
	width: 125px;
	height: 42px;
	/* UI Properties */
	background: #32aa4d 0% 0% no-repeat padding-box !important;
	border-radius: 4px !important;
	opacity: 1;
	color: white !important;
}

.breakWord {
	word-wrap: break-word;
}
